<template>
  <div class="product-grid product-grid_full">
    <div class="product-grid__title-wrapper mb_m">
      <div class="product-grid__title font font_title-xl font_bold font_center">
        {{ title }}
      </div>
    </div>

    <ul class="product-grid__list profile-promo__products-item">
      <li
        v-for="product in products"
        :key="product.id"
        ref="tabItems"
        class="product-grid__item"
        :data-key="product.id"
      >
        <ProductCard :item="product" page-type="main" :event-title="titleMain" :event-tab-name="tabName" />
      </li>
    </ul>
  </div>
</template>

<script>
import * as amplitudeTracker from '@amplitude/analytics-browser';
import ProductCard from '~/components/catalog/productCard';
export default {
  name: 'ProductGrid',
  components: {ProductCard},
  props: {
    title: {
      type: String,
      default: '',
    },
    titleMain: {
      type: String,
      default: '',
    },
    tabName: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visibleItems: [],
      amplitudeItems: [],
    }
  },
  mounted() {
    this.observeVisibility();
  },
  methods: {
    observeVisibility() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const tabId = entry.target.getAttribute('data-key');
            const product = this.products.find(
              product => product.id.toString() === tabId,
            );

            if (product) {
              this.visibleItems.push({
                item_name: product.name,
                item_id: `${product.productId}`,
                price: product.price.value,
                discount: '',
                item_brand: product.brandName,
                item_category: product.categoryName,
                item_list_name: `main ${this.titleMain} ${this.tabName}`,
              });
              this.amplitudeItems.push({
                item_id: `${product.productId}`,
                item_brand: product.brandName,
                item_name: product.name,
                price: product.price.value,
              });
              observer.unobserve(entry.target);
              this.sendGA4Event();
            }
          }
        });
      }, options);
      this.$refs.tabItems.forEach(slide => {
        observer.observe(slide);
      });
    },
    sendGA4Event() {
      if (this.visibleItems.length >= 7) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'view_item_list',
          ecommerce: {
            items: this.visibleItems,
          },
        });
        amplitudeTracker.track('view_selection', {
          selection_name: `${this.titleMain} ${this.tabName}`,
          products: this.amplitudeItems,
        });
      }
    },
  },
}
</script>

